<template>
    <files />   
</template>
<script>
import Files from '@/views/commons/components/clients/dashboard/options/files-module/Files.vue'
export default {
  components:{
    Files
  },
  data(){return{
    items:[
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '},
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '},
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '},
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '},
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '},
          {Name:'Personal info',Size:0.9,DOE:'aaa','Upload by':'Carlos Alvarez',Modified:'asdasd',Actions:' a | a '}
    ]
  }}
}
</script>